import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components';
export default (props) => {

  const ApplicationForm = styled('a')`
    background: #ff9500;
    border-radius: 2px;
    font-weight: bold !important;
    color: white !important;
    position: relative;
    padding-left: 12px!important;
    padding-right: 12px !important;
     transition: all 0.3s ease;
     @media (max-width: 1350px) {
      position: absolute; 
      right: 0px;
      top: -10px;
     }
      &:hover:after {
        transform: rotate(0deg) translateY(0px);
        left: 0px;
        width: 100%;
      }
    &:after {
      content: '';
      height: 4px;
      background: #5393fe;
      width: 120%;
      position: absolute;
      bottom: 0px;
      left: -12px;
      right: 0px;
      transform: rotate(-2deg) translateY(8px);
      transition: all 0.3s ease;
     
    }
  `;
  const { toggleMutator, toggleNav, location } = props;
  return (
  <StaticQuery
      query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
          }
        }
        allContentfulPage(filter: {node_locale: {eq: "en-US"}, order: {ne: null}}, sort: {fields: order, order: ASC}) {
          edges {
            node {
              id
              slug
              title
              order
            }
          }
        }

      }
    `}
      render={data =>
          (

              <header className="site-head">

                <div className="site-head-container">
                  <a
                      className="nav-burger"
                      href={`#`}
                      onClick={() => toggleMutator(!toggleNav)}
                  >
                    <div
                        className="hamburger hamburger--collapse"
                        aria-label="Menu"
                        role="button"
                        aria-controls="navigation"
                    >
                      <div className="hamburger-box">
                        <div className="hamburger-label-open">Menu</div>
                        <div className="hamburger-inner"/>
                      </div>
                    </div>
                  </a>
                  <Link className="site-head-logo" to={`/`}>
                    Kingscote Doodles
                  </Link>
                  <nav id="swup" className="site-head-left">
                    <ul className="nav" role="menu">
                      {/*<li className="nav-home nav-current" role="menuitem">*/}
                        {/*<Link to={`/`}>Home</Link>*/}
                      {/*</li>*/}


                      {data.allContentfulPage.edges.map(({ node }) => {
                        // Skip rendering this menu item if the slug contains 'next-litters'
                        if (node.slug.includes('next-litters')) {
                          return null;
                        }
                      
                        return (
                          <li key={node.slug} className={`${location.pathname === '/' + node.slug ? "nav-current" : ''} "nav-home"`} role="menuitem">
                            <Link to={node.slug}>{node.title}</Link>
                          </li>
                        );
                      })}


                    </ul>
                  </nav>

                  <div className="site-head-right">
                    <div className="social-links">
                      <Link title={'Photo Gallery of Labradoodles'} to={`/gallery`}>Photo Gallery</Link>
                      <Link to={`/questions-about-owning-a-labradoodle`}>FAQ</Link>

                      <Link to={"/blog"}>Blog</Link>
                      <a
                          href="https://www.facebook.com/kingscote.labradoodles/"
                          title="Facebook"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <img src={'/facebook.png'} title={'Kingscote Doodles Page'} style={{
                          marginRight: '5px',
                          position: 'relative',
                          top: '0px',
                          float: 'left',
                          maxWidth: 18 + 'px'
                        }}/>
                      </a>
                      <Link to={'/contact'}>Contact</Link>
                      <ApplicationForm target={'_blank'} href={"/next-litters"}>
                          Puppies Available
                      </ApplicationForm>

                    </div>
                  </div>
                </div>
              </header>
          )
      }
  /> )
 }
