import React, {useEffect} from "react"
import { Link } from "gatsby"
import Grid from 'styled-components-grid';
import Img from "gatsby-image"
import tokens from '../tokens'
import styled from "styled-components";
import Navigation from "./navigation";
import Helmet from 'react-helmet'

const LayoutSimple = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  useEffect( () => {
    try {

    } catch (e) {
      console.log("Couldn't execute from ComponentDidMount:", e);
    }

  });
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <Navigation  location={props.location} toggleNav={toggleNav} toggleMutator={setToggleNav}/>

    <div style={{ backgroundColor: '#ff834e', color: 'white', textAlign: 'center', padding: '20px 0', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
      <div style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>We have Puppies Available. Our Puppies will be ready to leave us around Spring/Early Summer 2025</div>
      <div>Please view our Application Form on our <a href="/next-litters" target="_blank" style={{ color: '#ffff00', textDecoration: 'none', fontWeight: 'bold' }}>Next Litters</a> for more information</div>
    </div>


  
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
          <div className="elfsight-app-8e4c194e-6610-4b85-b117-dae3d710c795"></div>
      </main>
      <Helmet>
          <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &mdash; Kingscote Doodles
      </footer>
    </div>
  )
}

export default LayoutSimple
