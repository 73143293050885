// ./tokens/index.js
import DesignSystem from 'design-system-utils'

// your design tokens object goes here, see below for further details
const designTokens = {
  colors: {
    // Used with `ds.color('colorName')`
    colorPalette: {
      bright: {
        base: '#F9FAFB',
        dark: '#F4F6F8',
        darker: '#DFE4E8',
      },

      dark: {
        base: '#212B35',
        light: '#454F5B',
        lighter: '#637381',
      },

      primary: {
        base: '#181830',
        light: '#292952',
        dark: '#0d0d19',
      },

      secondary: {
        base: '#fe7c08',
        light: '#fea04c',
        dark: '#d26401',
      },

      text: {
        base: '#212B35',
        light: '#454F5B',
      },

      background: {
        base: '#f1f1f1',
      },

      link: {
        base: '#fe7c08',
        over: '#fea04c',
      },
    },

    // Used with `ds.brand('colorName)`
    // With a brand palette like this:
    brand: {
      dex: '#5393fe',
      luna: '#17b14f',
      molly: '#A36FE6',
      bonnie: '#17B1B1',
      nel: '#ff9500',
      indie:'#e03996',
    }
  },
  type: {
    baseFontSize: '20px',

    sizes: {
      xs: '16px',
      s: '20px',
      base: '30px',
      m: '36px',
      l: '42px',
      xl: '50px',
      xxl: '58px',
    },




    fontFamily: {
      system:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans"',
      sans: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      serif: 'Georgia, "Times New Roman", Times, serif',
      mono: 'Menlo, Monaco, "Courier New", monospace',
    },

    lineHeight: {
      headings: 1.1,
    },

    fontWeight: {
      normal: 300, // Useful to set here if using anything other than `normal`
      bold: 'bold', // Useful to set here when bold webfonts come as 400 font-weight.
      headings: 'bold', // instead of browser default, bold
    },
  },
}

export default new DesignSystem(designTokens)